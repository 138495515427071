import classNames from "classnames";
import React from "react";
import CriticalAttendeeDisplay from "./criticalAttendeeDisplay";

export default function NameEmailDisplay({ attendee, criticalAttendees }) {
  const { name, email } = attendee;

  if (!name && !email) {
    // added a check for !email as a sanity check. Should never get here
    return (
      <div className="spreadsheet-participant-container group-vote-link-border-top pr-2.5">
        <div className="truncate-text max-width-160px font-size-12">
          {""}
        </div>
      </div>
    );
  }

  const renderEmail = () => {
    /* Don't render email twice */
    if (name === email) {
      return null;
    }

    return (
      <div
        className={
          classNames(
            "truncate-text max-width-160px font-size-12",
            !name ? "" : "secondary-text-color my-2",
          )
        }
      >
        {email}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "spreadsheet-participant-container group-vote-link-border-top pr-2.5",
        "flex flex-col justify-center",
      )}
      style={{ alignItems: "flex-start" }}
    >
      <div className="truncate-text max-width-160px font-size-12">{name}</div>
      {renderEmail()}
      <CriticalAttendeeDisplay attendee={attendee} criticalAttendees={criticalAttendees} />
    </div>
  );
}
