import classNames from "classnames";
import React, { createRef, useState } from "react";
import { SECOND_IN_MS } from "../../services/globalVariables";
import { useIsMounted } from "../../customHooks/useIsMounted";
import { getAttendeeEmail } from "./sharedFunctions";
import { getObjectEmail } from "../../lib/availabilityFunctions";
import { lowerCaseAndTrimStringWithGuard } from "../../services/commonUsefulFunctions";

const SHARED_CLASS_NAME =
  "name-and-email-input w-40 font-size-12 border-none font-weight-300 duration-200";
const NAME_INPUT_ID = "name-input";
const EMAIL_INPUT_ID = "email-input";
export default function NameAndEmailUnderEdit({
  attendee,
  attendeeList,
  onSave,
  showEmailWarning,
  resetWarning,
}) {
  const [showDupeEmailError, setShowDupeEmailError] = useState(false);
  const [email, setEmail] = useState(getAttendeeEmail(attendee));
  const [name, setName] = useState(attendee.name);
  const timeoutID = createRef(null);
  const componentIsMounted = useIsMounted();
  const onBlur = () => {
    clearTimeout(timeoutID.current);
    timeoutID.current = setTimeout(() => {
      if (!componentIsMounted.current) {
        return;
      }
      const activeElement = document.activeElement;
      if (!activeElement) {
        // nothing
      } else if (
        activeElement.id === NAME_INPUT_ID ||
        activeElement.id === EMAIL_INPUT_ID
      ) {
        // early exit
        return;
      }

      const attendeeEmails = attendeeList?.map(a => getObjectEmail(a)) ?? [];
      const formattedEmail = lowerCaseAndTrimStringWithGuard(email);

      /* Display dupe error */
      if (attendeeEmails.includes(formattedEmail) && getAttendeeEmail(attendee) !== formattedEmail) {
        setShowDupeEmailError(true);
        return;
      }

      // if both are not focused anymore -> save
      onSave({ email, name });
    }, 0.5 * SECOND_IN_MS);
  };
  return (
    <div
      className={classNames(
        "spreadsheet-participant-container group-vote-link-border-top pr-2.5",
        "flex flex-col justify-center"
      )}
      style={{ alignItems: "flex-start" }}
    >
      <input
        className={classNames(SHARED_CLASS_NAME)}
        id={NAME_INPUT_ID}
        value={name}
        placeholder="Name"
        onChange={(e) => {
          setName(e.target.value);
        }}
        autoFocus={true}
        onBlur={onBlur}
      />
      <input
        className={classNames(SHARED_CLASS_NAME, "mt-2")}
        id={EMAIL_INPUT_ID}
        value={email}
        placeholder="Email"
        onChange={(e) => {
          if (showEmailWarning) {
            resetWarning();
          }

          if (showDupeEmailError) {
            setShowDupeEmailError(false);
          }

          setEmail(e.target.value);
        }}
        onBlur={onBlur}
      />
      {showEmailWarning ? (
        <div className="font-size-12 font-weight-300 text-red-500 mt-2">
          Please enter a valid email
        </div>
      ) : null}
      {showDupeEmailError ? (
        <div className="font-size-12 font-weight-300 text-red-500 mt-2">
          Email address already in use
        </div>
      ) : null}
    </div>
  );
}
